import { css } from '@emotion/react'
import { colors, font, mq } from 'plume-ui'
import { NPFeed, TopPick } from '../../../types'
import { LineClampText } from '../../atoms/LineClampText'
import React from 'react'
import Link from 'next/link'
import { isBannerPosition } from '@/lib/npFeeds'
import { addNewsPicksTrackingParam, resizeBannerImageUrl } from '@/lib/url'
import { NPFeedPublisherAndPublishedDate } from '../../common/npStartup/NPFeedPublisherAndPublishedDate'
import { PickersIcons } from '../../common/npStartup/PickersIcons'
import { NewsPicksStartupMoreLink } from './NewsPicksStartupMoreLink'

type Props = {
  feeds: NPFeed[]
}

export const MainSection: React.VFC<Props> = (props) => {
  const showedFeeds = props.feeds.slice(0, 15)
  return (
    <div
      css={css`
        ${mq.untilMedium} {
          padding: 0 12px;
        }
      `}
    >
      <div
        className="np-feed"
        css={css`
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          gap: 32px;
          ${mq.untilMedium} {
            justify-content: center;
            gap: 8px;
          }
        `}
      >
        {showedFeeds.map((feed, i) => (
          <>
            <NewsPicksFeed key={i} index={i + 1} feed={feed} large={i === 0} />
            {isBannerPosition(showedFeeds.length, i) && (
              <ReportBanner key="report-banner" />
            )}
          </>
        ))}
      </div>
      <div
        css={css`
          margin-block: 32px;
          ${mq.untilMedium} {
            margin-block-end: 0;
          }
        `}
      >
        <NewsPicksStartupMoreLink />
      </div>
    </div>
  )
}

const ReportBanner: React.VFC = () => {
  return (
    <Link href="https://jp.ub-speeda.com/document/240730wp/" passHref>
      <a className="report-banner" target="_blank" rel="noopener noreferrer">
        <div
          css={css`
            overflow: hidden;
            height: 240px;
            border-radius: 8px;
            position: relative;
            &:after {
              position: absolute;
              display: block;
              content: '';
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background-color: transparent;
              opacity: 0.08;
              transition: background-color 0.2s ease;
            }
            &:hover:after {
              background-color: #000000;
              transition: background-color 0.2s ease;
            }
            ${mq.untilMedium} {
              display: none;
            }
          `}
        >
          <img
            css={css`
              width: 100%;
            `}
            src="/static/images/top/bn_startupfinance2024h1_pc.jpg"
          />
        </div>
        <img
          css={css`
            width: 100%;
            border-radius: 8px;
            display: none;
            ${mq.untilMedium} {
              display: inline-block;
              vertical-align: top;
            }
          `}
          src="/static/images/top/bn_startupfinance2024h1_sp.jpg"
        />
      </a>
    </Link>
  )
}

const NewsPicksFeed: React.VFC<{
  feed: NPFeed
  index: number
  large?: boolean
}> = ({ feed, index, large = false }) => {
  return (
    <div
      className={large ? 'np-feed-item large' : 'np-feed-item'}
      css={css`
        width: ${large ? '736px' : '352px'};
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
        &:hover {
          box-shadow: 0px 4px 24px rgb(0 0 0 / 12%);
        }
        ${mq.untilMedium} {
          width: 100%;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        }
        background: ${colors.white00};
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.4s ease 0s;
      `}
    >
      <NewsPicksFeedDetail feed={feed} index={index} large={large} />
      <hr
        css={css`
          margin: 16px 16px;
          border: none;
          height: 1px;
          background-color: ${colors.gray50};
        `}
      />
      {feed.topPick && (
        <NewsPicksTopPick
          index={index}
          topPick={feed.topPick}
          articleUrl={feed.articleUrl}
          large={large}
          newsId={feed.newsId}
        />
      )}
    </div>
  )
}

type FeedDetailProps = { feed: NPFeed; index: number; large: boolean }

const NewsPicksFeedDetail: React.VFC<FeedDetailProps> = (props) => {
  const pickersForDisplay = props.feed.pickers.slice(0, 5)
  return (
    <div>
      <NewsPicksFeedsBannerAndTitle
        feed={props.feed}
        index={props.index}
        large={props.large}
      />
      <div
        css={css`
          margin: 0px 16px;
        `}
      >
        <NPFeedPublisherAndPublishedDate
          css={css`
            margin-bottom: 12px;
          `}
          {...props.feed}
          fontSize={'12px'}
          lineHeight={1.16666}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              margin-right: 8px;
              height: 28px;
            `}
          >
            <PickersIcons
              pickers={pickersForDisplay}
              className="np-feed-pickers"
            />
          </div>
          <div
            css={css`
              width: fit-content;
              color: ${colors.black30};
              line-height: 100%;
            `}
            className="np-feed-pick-count"
          >
            <span
              css={css`
                margin-right: 2px;
                font-weight: ${font.weight.bold};
                font-size: 16px;
              `}
            >
              {props.feed.pickCount}
            </span>
            <span
              css={css`
                font-size: 14px;
              `}
            >
              Picks
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export const NewsPicksFeedsBannerAndTitle: React.VFC<{
  feed: NPFeed
  index: number
  large?: boolean
}> = (props) => {
  const bannerImageUrl =
    props.feed.image || '/static/images/top/np-feed-blank-banner.jpg'
  const articleUrl = addNewsPicksTrackingParam(
    props.feed.articleUrl,
    props.feed.newsId
  )
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        ${mq.untilMedium} {
          flex-direction: ${props.large ? 'column' : 'row-reverse'};
          justify-content: space-between;
          margin: ${props.large ? '0 0 8px' : '16px 0 8px'};
        }
      `}
    >
      <div
        css={css`
          ${mq.untilMedium} {
            display: flex;
            align-items: center;
          }
        `}
      >
        <div
          className="np-feed-banner"
          css={css`
            width: 100%;
            overflow: hidden;
            ${mq.untilMedium} {
              width: ${props.large ? '100%' : '112px'};
              display: flex;
              align-items: center;
              margin-right: ${props.large ? '0' : '16px'};
              margin-bottom: ${props.large ? '16px' : '0'};
              border-radius: 2px;
            }
          `}
        >
          <Link href={articleUrl} passHref>
            <a
              id={`toppage-banner-${props.index}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                css={css`
                  position: relative;
                  justify-content: center;
                  &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                    opacity: 0.08;
                    transition: background-color 0.2s ease;
                  }
                  &:hover:after {
                    background-color: #000000;
                    transition: background-color 0.2s ease;
                  }
                `}
              >
                <img
                  src={resizeBannerImageUrl(
                    bannerImageUrl,
                    props.large ? 736 : 600,
                    props.large ? 385 : 315
                  )}
                  css={css`
                    display: block;
                    width: 100%;
                    object-fit: contain;
                    aspect-ratio: 1.91 / 1;
                  `}
                />
              </div>
            </a>
          </Link>
        </div>
      </div>
      <div
        css={css`
          margin: 16px 16px 8px;
          font-weight: ${font.weight.bold};
          font-size: 18px;
          line-height: 150%;
          min-height: ${props.large ? 'unset' : '54px'};
          overflow: hidden;
          ${mq.untilMedium} {
            width: calc(100% - 32px);
            margin: 0 8px 0 16px;
            min-height: ${props.large ? 'unset' : '46px'};
            height: auto;
            display: flex;
            align-items: center;
          }
        `}
      >
        <Link href={articleUrl} passHref>
          <a
            id={`toppage-title-${props.index}`}
            className="np-feed-title"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              color: ${colors.black10};
              text-decoration: underline solid transparent;
              transition: text-decoration 0.2s ease 0s;
              &:hover {
                text-decoration: underline solid Currentcolor;
                transition: text-decoration 0.2s ease 0s;
              }
            `}
          >
            <LineClampText
              className="np-feed-title-text"
              lineNumber={{ desktop: 2, mobile: 2 }}
              size={props.large ? '24px' : '18px'}
              height={1.5}
              css={css`
                ${mq.untilMedium} {
                  font-size: ${props.large ? '18px' : '15px'};
                }
              `}
            >
              {props.feed.title}
            </LineClampText>
          </a>
        </Link>
      </div>
    </div>
  )
}

type TopPickProps = {
  large: boolean
  index: number
  topPick: TopPick
  articleUrl: string
  newsId: number
}

const NewsPicksTopPick: React.VFC<TopPickProps> = (props) => {
  const articleUrl = addNewsPicksTrackingParam(props.articleUrl, props.newsId)
  return (
    <Link href={articleUrl} passHref>
      <a
        id={`toppage-toppick-${props.index}`}
        target="_blank"
        className="np-feed-top-pick"
        css={css`
          color: ${colors.black10};
          margin: 0px 16px 24px;
          text-decoration: none;
          transition: all 0.2s ease;
          display: block;
          &:hover {
            transition: all 0.2s ease;
            opacity: 0.8;
          }
          ${mq.untilMedium} {
            margin: 0px 16px 16px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          `}
        >
          <img
            alt="picker icon"
            className="np-feed-picker-icon"
            css={css`
              height: 32px;
              width: 32px;
              border-radius: 50%;
              margin-right: ${props.large ? '10px' : '8px'};
              display: block;
            `}
            src={
              props.topPick.picker.image
                ? props.topPick.picker.image
                : '/static/images/top/np-picker-blank-avatar.png'
            }
          />
          <div
            css={css`
              flex-grow: 1;
              min-width: 0;
            `}
          >
            <div
              css={css`
                margin-bottom: ${props.topPick.picker.title ? '4px' : '3px'};
              `}
            >
              <span
                className="np-feed-picker-name"
                css={css`
                  font-weight: ${font.weight.bold};
                  font-size: 14px;
                  line-height: 15px;
                  margin-right: 4px;
                  vertical-align: middle;
                `}
              >
                {props.topPick.picker.name}
              </span>
              {props.topPick.picker.isProPicker && (
                <ProPickerBadge className="np-feed-pro-picker-badge" />
              )}
            </div>
            <div
              className="np-feed-picker-title"
              css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 12px;
                line-height: 100%;
                color: ${colors.textLight};
              `}
            >
              {props.topPick.picker.title}
            </div>
          </div>
        </div>
        <div
          css={css`
            margin-left: 40px;
          `}
        >
          <div
            css={css`
              color: ${colors.black30};
              overflow: hidden;
            `}
          >
            {props.topPick.comment ? (
              <LineClampText
                className="np-feed-comment"
                lineNumber={3}
                size="13px"
                height={1.5}
              >
                {props.topPick.comment}
              </LineClampText>
            ) : (
              <div>
                <span
                  className="np-feed-comment"
                  css={css`
                    font-size: 13px;
                    line-height: 1.4;
                  `}
                >
                  まだコメントがありません。
                </span>
              </div>
            )}
          </div>
        </div>
      </a>
    </Link>
  )
}

const ProPickerBadge: React.VFC<{ className?: string }> = ({ className }) => {
  return (
    <span
      className={className}
      css={css`
        display: inline-flex;
        align-items: center;
        text-align: center;
        width: min-content;
        height: min-content;
        vertical-align: middle;
        font-style: italic;
        font-size: 10px;
        line-height: 100%;
        border-radius: 8px;
        padding: 2px 6px 1px 5px;
        background-color: ${colors.black10};
        color: ${colors.white00};
      `}
    >
      PRO
    </span>
  )
}
